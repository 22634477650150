import React from 'react';
import { Modal, Button } from '@material-ui/core';

export const FallbackComponent = () => {
  return (
    <div>
      <Modal title="Erreur" open className="modal">
        <div className="modal-content">
          <p>Une erreur vient de se produire.</p>

          <p>
            Un rapport détaillé vient d&apos;être envoyé à notre équipe afin que
            nous puissions améliorer notre logiciel.
          </p>

          <p>
            Si l&apos;erreur persiste, n&apos;hésitez pas à nous contacter
            directement.
          </p>

          <p>En vous remerciant pour votre compréhension</p>
          <Button type="primary" onClick={() => window.open('/', '_self')}>
            Retour à l&apos;accueil
          </Button>
        </div>
      </Modal>
    </div>
  );
};
